<template>
  <v-form v-model="valid" :readonly="pending" @submit.prevent="() => onSubmit()">
    <v-card-text>
      <v-file-input
        v-model="files"
        :label="$t('pickFiles')"
        :accept="acceptValidation"
        multiple
        clearable
        chips
        counter
        show-size
        :rules="rules"
        :hint="`${$t('maxFiles', { max: maxFiles })}, ${$t('maxFileSize', { max: prettyBytes(maxFileSize) })}`"
        persistent-hint
      />
    </v-card-text>

    <v-card-actions class="justify-end">
      <v-btn color="primary" @click="onCancel">{{ $t("cancel") }}</v-btn>
      <v-btn color="primary" type="submit" :disabled="!(files && files.length)" :loading="pending">{{ $t("save") }}</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script setup lang="ts">
import prettyBytes from "pretty-bytes"

const $i18n = useI18n()
const config = useRuntimeConfig()

const mainStore = useMainStore()
const authStore = useAuthStore()
const { isSuperAdmin } = storeToRefs(authStore)

const emit = defineEmits<{
  (event: "ok", value: Array<FileData>): void
  (event: "raw", value: Array<File>): void
  (event: "cancel"): void
}>()

const props = defineProps<{
  accept?: string
  clientOnly?: boolean
  createFunction?: (files: Array<File>) => Promise<Array<FileData>>
}>()
const { accept, clientOnly, createFunction } = toRefs(props)

const files = ref<Array<File> | undefined>()
const pending = ref(false)
const valid = ref(false)

const maxFileSize = Number(config.public.MAX_FILE_SIZE_IN_BYTES)
const maxFiles = Number(config.public.MAX_FILES_IN_BATCH)

const rules = computed(() => {
  const isFileTypeValid = (fileType: string) => {
    const mime = accept.value
    if (!mime) {
      return true
    }

    if (mime.endsWith("*")) {
      return fileType.startsWith(mime.replace("*", ""))
    }

    return mime.includes(fileType)
  }

  const everyone = [
    (val: File[]) => !!val?.length || $i18n.t("required"),
    (val: File[]) => (val && val.every((file: File) => isFileTypeValid(file.type))) || $i18n.t("invalidFile"),
  ]

  if (isSuperAdmin.value) {
    return everyone
  }

  return [
    ...everyone,
    (val: File[]) => (val && val.length <= maxFiles) || $i18n.t("maxFiles", { max: maxFiles }),
    (val: File[]) =>
      (val && val.every((file: File) => file.size <= maxFileSize)) || $i18n.t("maxFileSize", { max: prettyBytes(maxFileSize) }),
  ]
})

const acceptValidation = computed(() => {
  // If accept value is image/* we should accept all images.
  // However, Android 12+ shows the file browser instead of the camera/browser option.
  // When we remove the accept and verify this manually Android does show the camera or browser choice.
  // https://support.google.com/chrome/thread/253908853/android-14-camera-tile-removed-from-photo-picker?hl=en
  if (!accept.value || accept.value.endsWith("*")) {
    return undefined
  }
  return accept.value
})

const onSubmit = () => {
  if (!valid.value || !files.value) {
    return
  }

  useExplicitSave(mainStore.notify, $i18n, async () => {
    if (!files.value) {
      return
    }

    pending.value = true

    try {
      if (clientOnly.value) {
        emit("raw", files.value)
      } else {
        if (!createFunction?.value) {
          throw new Error("No create function provided")
        }
        const newFiles = await createFunction.value(files.value)
        emit("ok", newFiles)
      }
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      console.error(errorMessage)
      throw e
    } finally {
      pending.value = false
    }
  })
}
const onCancel = () => {
  emit("cancel")
}
</script>
